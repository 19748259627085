import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CloseIcon } from './Svgs'

export default function RightModal({ isActive, children, onClose, animation, helper }) {
    useEffect(() => {
        const onCloseWithAnimation = () => {
            const modalElement = document.getElementById('modal')
            modalElement.classList.add('fade-in-left')
            setTimeout(() => {
                onClose()
            }, 500)
        }
        const handleKeyboard = (event) => {
            if (event.keyCode === 27) {
                onCloseWithAnimation()
            }
        }

        window.addEventListener('keydown', handleKeyboard)

        return () => {
            window.removeEventListener('keydown', handleKeyboard)
        }
    }, [onClose])

    return createPortal((
        <div id="modal" className={cn('modal', animation ? 'fade-in-right' : null, css(styles.modal), { 'is-active': isActive })}>
            <div className="modal-background" onClick={onClose} />

            <div style={helper?.style} className={cn('modal-card', css(styles.modalCard), helper?.className)}>
                <div className={cn('modal-card-body', css(styles.modalCardBody))}>
                    {children}
                </div>
                <button className={cn('', css(styles.button))} onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    ), document.querySelector('#modals-root'))
}

const styles = StyleSheet.create({
    modal: {
        alignItems: 'start',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    modalCard: {
        maxHeight: '100%',
        minHeight: '100%',
        marginRight: 0,
        maxWidth: 800,
    },
    modalCardBody: {
        paddingTop: 50,
    },
    button: {
        position: 'absolute',
        border: 'none',
        background: 'none',
        right: 5,
        top: 5,
        cursor: 'pointer',
    },
})
