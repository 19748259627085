import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Button from './common/Button'
import { SEND_MESSAGE } from '../urls'
import { useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateOtherFormat, timeAgo } from '../utils/date'
import { format } from '../utils/number'
import ClientUpdate from './ClientUpdate'
import { useMessage } from '../hooks/message'

export default function BorrowItem({ item, clients, onCheckboxChange }) {
    const sendMessage = useGetRequest({ url: SEND_MESSAGE.replace('{clientId}', item.id) })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage(`Сизнинг sms хабарингиз ${item.name} га муваффақиятли юборилди`, 'is-success')
        }
    }

    // const productRemove = useDeleteRequest({ url: CLIENT_DETAIL.replace('{id}', item.id) })

    // async function onDelete() {
    //     if (global.confirm('Вы действительно хотите удалить?')) {
    //         await productRemove.request()
    //         clients.request()
    //     }
    // }

    const [showUpdateModal, hideUpdateModal] = useModal(<ClientUpdate clients={clients} item={item} onCancel={() => hideUpdateModal()} />)

    const currentDate = new Date()

    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked
        setIsChecked(newCheckedState)
        onCheckboxChange(item.id, newCheckedState)
    }

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger is-size-6' : 'has-text-black is-size-6'}`} key={item.id}>
            <td className="is-size-6">{item?.id}</td>
            <td className="is-size-6">
                <Link to={`/borrow/${item.id}`} className={cn('', `${item.sumAmount > 0 ? 'has-text-link' : 'has-text-link'}`)}>
                    {item.name}
                </Link>
            </td>
            <td className="is-size-6">{item.phoneNumber}</td>
            <td className={cn('is-size-6', `${(item.sumAmount || item.usdAmount) < 0 ? 'has-text-danger' : 'has-text-success'}`)}>{`${format(item.sumAmount)} Сум`}
                <br /> {item.usdAmount < 0 ? `${format(item.usdAmount)} USD` : null}
            </td>
            <td>
                <input className="checkbox" style={{ width: 25, height: 25 }} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            </td>
            <td className="">{item.lastSendDate ? timeAgo(item.lastSendDate) : ''}</td>
            <td className="has-text-right">
                <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                {/* <Button
                    onClick={onDelete}
                    className="button is-danger mr-2 is-outlined is-small"
                    icon="trash" /> */}

                <Button loading={sendMessage.loading} onClick={onSend} className="button is-link is-outlined is-small" icon="paper-plane-outline" />
            </td>
        </tr>
    )
}
