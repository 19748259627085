export const types = [
    { value: 'outcome', label: 'Расход' },
    { value: 'income', label: 'Приход' },
]

export const measurements = [
    { name: 'шт', value: 'pieces' },
    { name: 'литр', value: 'litres' },
    { name: 'м', value: 'metr' },
    { name: 'кг', value: 'kg' },
]

export function getMeasurement(value) {
    return measurements.find((item) => item.value === value)?.name
}

export const currency = [
    { name: 'Сум', value: 'sum' },
    { name: 'USD', value: 'usd' },
]

export function getCurrency(value) {
    return currency.find((item) => item.value === value)?.name
}

export const payment = [
    { name: 'Приход', value: 'coming' },
    { name: 'Расход', value: 'consumption' },
]

export function getPayment(value) {
    return payment.find((item) => item.value === value).name
}

export const pricedType = [
    { name: 'Наличка', value: 'cash' },
    { name: 'Терминал', value: 'terminal' },
    { name: 'Перечисления', value: 'transfers' },
]

export const paymentMethods = [
    { name: 'Наличные', value: 'cash' },
    { name: 'Терминал', value: 'terminal' },
    { name: 'Долг', value: 'debt' },
]

export const paid = [
    { name: 'Оплачено', value: 'paid' },
    { name: 'Не оплачено', value: 'not_paid' },
]
export const paymentMethod = [
    { name: 'Наличные', value: 'cash' },
    { name: 'Перечисления', value: 'transfers' },
]

export function getPricedType(value) {
    return pricedType.find((item) => item.value === value).name
}

export function getPaymentMethod(value) {
    return paymentMethod.find((item) => item.value === value).name
}

export function getPaymentMethods(value) {
    return paymentMethods.find((item) => item.value === value).name
}

export function getPaid(value) {
    return paid.find((item) => item.value === value).name
}

export const statuses = [
    { name: 'Новый', value: 'new' },
    { name: 'Принят', value: 'accepted' },
    { name: 'Доставлен', value: 'delivered' },
    { name: 'Отменён', value: 'canceled' },
]

export const statusTypes = [
    { name: 'Товар возвращен частично', value: 'item_returned_partially' },
    { name: 'Товар возвращен', value: 'item_returned' },
    { name: 'Успешно', value: 'successfully' },
]

export const tradeTypes = [
    { id: 0, name: 'В розницу', value: 'retail' },
    { id: 1, name: 'Оптом', value: 'wholesale' },
]
export function getTradeTypeName(id) {
    return tradeTypes.find((item) => item.id === id)?.name
}

export function getStatusTypes(value) {
    return statusTypes.find((item) => item.value === value)?.name
}
