import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { DOCUMENTS_LIST } from '../urls'
import { useQueryParams } from '../hooks/queryString'
import Search from '../components/Search'
import Button from '../components/common/Button'
import DocumentsItem from '../components/DocumentsItem'

export default function Documents() {
    const [page, setPage] = useState(1)

    const params = useQueryParams()
    const suppliers = useLoad({ url: DOCUMENTS_LIST, params: { ...params, page } }, [page])
    const history = useHistory()

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout>
            <div className="is-flex mb-3">
                <Search onSearch={onSearch} />

                <Button
                    // onClick={excelProduct.request}
                    icon="cloud-upload-outline"
                    className="button is-link is-outlined ml-2 mr-2"
                    text="Экспорт"
                />

                <label style={{ position: 'relative' }}>
                    <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                    {/* eslint-disable-next-line max-len */}
                    <input
                        value=""
                        onChange={() => {}}
                        type="file"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                        }}
                    />
                </label>

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={() => history.push('/documents-in/create')} />
            </div>

            <Table
                pagination
                activePage={page}
                onPageChange={setPage}
                loading={suppliers.loading}
                items={suppliers.response ? suppliers.response.results : []}
                columns={{
                    id: '',
                    name: 'Поставщик\t',
                    phone: '№ Документ',
                    phone_director: 'Сумма\t',
                    phone_accountant: 'Дата приема\t',
                    buttons: '',
                }}
                totalCount={suppliers.response ? suppliers.response.count : 0}
                s
                renderItem={(item, index) => <DocumentsItem index={index} key={item.id} item={item} suppliers={suppliers} />}
            />
        </Layout>
    )
}
