import React from 'react'
import { usePostRequest } from '../hooks/request'
import { BORROW_LIST } from '../urls'
import BorrowClientForm from './BorrowClientForm'

export default function BorrowClientCreate({
    onCancel,
    item,
    order,
    setBorrow,
    total,
    clientId,
    result,
    check,
    orderId,
}) {
    const products = usePostRequest({ url: BORROW_LIST })
    async function onSubmit(data) {
        const { success } = await products.request({
            data: {
                order: orderId,
                result,
                client: clientId,
                description: `${order.orderProduct.map((elem) => elem.product.name)}`,
                amount: total,
                termDate: data.termDate,
                smsStatus: data.smsStatus,
            },
        })

        if (success) {
            check.request()
            onCancel()
        }
    }
    return (
        <BorrowClientForm
            initialValues={{ ...item }}
            setBorrow={setBorrow}
            loading={products.loading}
            onCancel={onCancel}
            onSubmit={onSubmit} />

    )
}
