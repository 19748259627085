import cn from 'classnames'
import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'

import { format } from '../utils/number'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { ORDER_ARCHIVE_LIST } from '../urls'
import { paymentMethods } from '../utils/types'
import empty from '../static/empty-product.png'
import ArchiveOrderInfo from './ArchiveOrderInfo'
import ArchiveItem from '../components/ArchiveItem'
import NewTable from '../components/common/NewTable'
import { useQueryParams } from '../hooks/queryString'
import { useRightModal } from '../hooks/useRightModal'
import DateFilter from '../components/common/DateFilter'
import SummaryCard from '../components/common/SummaryCard'
import SearchInput2 from '../components/common/SearchInput2'
import FilterSelect from '../components/common/FilterSelect'
import SearchFilter from '../components/common/SearchFIlter'

export default function Archive() {
    const [searchParams, setSearchParams] = useState({})
    const params = useQueryParams()
    const ordersList = useLoad({ url: ORDER_ARCHIVE_LIST, params: { ...params, ...searchParams } }, [searchParams])
    const orders = ordersList.response?.results || []
    // const [selectedItem, setSelectedItem] = useState()
    const totalForOrderHistoriesSum = ordersList.response ? ordersList.response.totalForOrderHistoriesSum : 0
    const totalForOrderHistoriesSumBuy = ordersList.response ? ordersList.response.totalForOrderHistoriesSumBuy : 0
    const total = totalForOrderHistoriesSum - totalForOrderHistoriesSumBuy

    const [showInfoModal, hideInfoModal] = useRightModal((item) => <ArchiveOrderInfo onCancel={() => hideInfoModal()} item={item} />)

    const handleClick = (item) => {
        // setSelectedItem(item)
        showInfoModal(item)
    }
    return (
        <Layout>
            <header className="mb-5" style={{ gap: '1rem', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(10rem, auto))' }}>
                <DateFilter className="mr-2" name="date" />

                <SearchFilter />

                <SearchInput2 name="amountFrom" placeholder="Сумма от" type="number" searchParams={searchParams} setSearchParams={setSearchParams} />
                <SearchInput2 name="amountUpTo" placeholder="Сумма до" type="number" searchParams={searchParams} setSearchParams={setSearchParams} />
                <DateFilter name="startDate" />
                <DateFilter name="endDate" />
                <FilterSelect name="payment_method" options={paymentMethods} optionValueKey="value" placeholder="Способ оплаты" />
            </header>

            <hgroup style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(20rem, 1fr))', gap: '1rem', marginBottom: '2rem' }}>
                <SummaryCard label="Общая сумма продаж:" value={`${format(totalForOrderHistoriesSum)} сум`} />
                <SummaryCard label="Общая сумма доход:" value={`${format(total)} сум`} />
            </hgroup>

            <main style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 'auto' }}>
                    {orders.response && orders.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (
                        <NewTable
                            loading={ordersList.loading}
                            items={orders}
                            columns={{
                                index: '',
                                status: 'Статус',
                                codeContent: 'Номер чека',
                                name: 'Название',
                                price: 'Номер телефона',
                                ds: 'Итого',
                                registered_at: 'Создан в ',
                                paymentMethod: 'Способ оплаты*  ',
                                actions: '',
                            }}
                            totalCount={ordersList.response ? ordersList.response.count : 0}
                            pageSize={15}
                            renderItem={(item, index) => (
                                <tr
                                    key={item.id}
                                    style={{ cursor: 'pointer' }}
                                    // className={cn({ 'has-background-info-light': selectedItem?.id === item?.id })}
                                    onClick={() => {
                                        handleClick(item)
                                    }}
                                >
                                    <ArchiveItem index={index} ordersList={ordersList} item={item} />
                                </tr>
                            )}
                        />
                    )}
                </div>

                {/* <aside style={{ width: '30%',
                    backgroundColor: 'ghostwhite',
                    borderRadius: '5px',
                    padding: '10px',
                    position: 'relative' }}
                >
                    {selectedItem && (
                        <>
                            <main>
                                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                    <b>Дата:</b>
                                    <span style={{ fontWeight: 500 }}>
                                        {moment(selectedItem?.createdAt).format(DATETIME_FORMAT)}
                                    </span>
                                </p>
                                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                    <b>ID-чека:</b>
                                    <span style={{ fontWeight: 500 }}>
                                        {selectedItem?.codeContent}
                                    </span>
                                </p>

                                <div style={{ height: 1, backgroundColor: 'black', margin: '8px 0' }} />

                                {selectedItem?.orderProduct?.map((item, i) => (
                                    <div key={item?.id} style={{ marginBottom: '1rem' }}>
                                        <h3 style={{ fontWeight: 'bold' }}>{i + 1}. {item?.product?.name}</h3>

                                        <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                            <span>Цена:</span>
                                            <span style={{ fontWeight: 500 }}>
                                                {item.count} {getMeasurement(item?.product?.measurement)} x {format(item.price)} сўм = <b>{format(item.count * item.price)} сўм</b>
                                            </span>
                                        </p>
                                    </div>
                                ))}

                                <div style={{ height: 1, backgroundColor: 'black', margin: '10px 0' }} />

                                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                    <span style={{ fontWeight: 'bold' }}>Итого:</span>
                                    <b>
                                        {format(selectedItem?.sumComing)} сўм
                                    </b>
                                </p>
                            </main>

                            <div
                                style={{ marginTop: '1rem' }}
                            >
                                <Link to={`/archive/orders/${selectedItem.id}`} className="button is-danger is-block">
                                    Возврат
                                </Link>
                            </div>
                        </>
                    )}
                </aside> */}
            </main>
        </Layout>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' }, emptyImg: { width: '330px', height: '300px' }, loader: { marginTop: '2rem' }, modal: { height: 400 } })
