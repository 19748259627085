import React from 'react'
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        maxHeight: '80vh',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,
    },
}

Modal.setAppElement('#root')

export default function ReactModal({ isOpen = false, style, setIsOpen, children, ...props }) {
    function closeModal() {
        setIsOpen(false)
    }
    return (
        <Modal
            isOpen={isOpen}
            style={{
                content: { ...customStyles.content, ...style?.content },
                overlay: { ...customStyles.overlay, ...style?.overlay },
            }}
            // ariaHideApp={false}
            onRequestClose={closeModal}
            {...props}
        >
            {children}
        </Modal>
    )
}
