import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Button from './common/Button'
import { getDateTime } from '../utils/date'
import { format } from '../utils/number'
import { getCurrency } from '../utils/types'

export default function DocumentsItem({ item, index }) {
    return (
        <tr className="is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>

            <td className="is-size-6">{item.supplierSelect.name}</td>

            <td className="is-size-6">{item.phone}</td>
            <td className="is-size-6">
                {item.sumComing ? format(item.sumComing) : 0} {item.currency ? getCurrency(item.currency) : null}
            </td>
            <td className="is-size-6">{getDateTime(item.createdAt)} </td>
            <td className="">
                <div className="is-pulled-right">
                    <Link to={`/documents-in/preview/${item?.id}`}>
                        <Button className="button is-info mr-2 is-outlined is-small" icon="eye" />
                    </Link>
                </div>
            </td>
        </tr>
    )
}
