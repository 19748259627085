import React from 'react'
import Button from './common/Button'
import { BORROW_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import BorrowUpdate from './BorrowUpdate'
import { getDateOtherFormat, getDateTime } from '../utils/date'
import { format } from '../utils/number'
import { useCustomModal } from '../hooks/customModal'
import { useRightModal } from '../hooks/useRightModal'
import BorrowOrderInfo from '../pages/BorrowOrderInfo'
import { getCurrency } from '../utils/types'

export default function ClientBorrowItem({ reload, item, borrows, index }) {
    const clientBorrowRemove = useDeleteRequest({ url: BORROW_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await clientBorrowRemove.request()
            borrows.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useCustomModal(<BorrowUpdate borrows={borrows} reload={reload} item={item} hideModal={() => hideUpdateModal()} />)

    const currentDate = new Date()

    const [showInfoModal, hideInfoModal] = useRightModal((order) => <BorrowOrderInfo debt={item.amount} onCancel={() => hideInfoModal()} item={order} />)

    const handleClick = (order) => {
        // setSelectedItem(item)
        showInfoModal(order)
    }
    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger is-size-6' : 'has-text-black is-size-6'}`} key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className={item.amount < 0 ? 'is-size-6 has-text-danger' : 'is-size-6'}>{format(item.amount)} {getCurrency(item.currency)}</td>
            <td className="is-size-6">{item.description}</td>
            <td className="is-size-6">{getDateTime(item.createdAt)}</td>
            <td className="is-size-6">{item.termDate}</td>

            <td className="">
                <div className="is-pulled-right">
                    <Button
                        onClick={() => {
                            handleClick(item.selectOrder)
                        }}
                        className="mr-2 is-info is-outlined is-small"
                        icon="eye-outline"
                    />
                    <Button onClick={showUpdateModal} className="mr-2 is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="is-danger is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
