import React from 'react'
import Button from './common/Button'
import { ORDER_PRODUCT_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { format } from '../utils/number'
import { useModal } from '../hooks/modal'
import ProductUpdatelModal from './ProductUpdateModal'

export default function CheckProductItem({
    reload,
    element,
    check,
    products,

}) {
    const orderProductRemove = useDeleteRequest({ url: ORDER_PRODUCT_DETAIL.replace('{id}', element.id) })

    async function onDeleteOrderProduct() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await orderProductRemove.request()
            await reload.request()
            await products.request()
        }
    }

    const [showDetailModal, hideDetailModal] = useModal(
        <ProductUpdatelModal
            check={check}
            orderId={element.id}
            item={element}
            hideModal={() => hideDetailModal()}
        />,
    )
    return (

        <tr style={{ textAlign: 'center' }} key={element.id} className="is-size-6">
            <td style={{ width: 120 }} className="is-size-6">{element.product.name}</td>
            <td style={{ width: 50 }} className="is-size-6">{element.count} </td>
            <td className="is-size-6">{element.price}</td>
            <td className="is-size-6">{format(element.price * element.count)}</td>
            <td style={{ textAlign: 'center' }} className="is-size-6">
                <Button
                    onClick={showDetailModal}
                    className="button is-success is-pulled-center is-outlined is-small mb-3"
                    icon="pencil" /> <br />
                <Button
                    onClick={onDeleteOrderProduct}
                    className="button is-danger is-pulled-center is-outlined is-small"
                    icon="trash" />

            </td>
        </tr>

    )
}
