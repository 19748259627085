/* eslint-disable max-len */
import React from 'react'
import { NavLink, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { css, StyleSheet } from 'aphrodite'
import { FieldArray, Form, Formik } from 'formik'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import { useLoad, usePutRequest } from '../hooks/request'
import { ORDER_ARCHIVE_LIST, ORDER_DETAIL } from '../urls'
import { DATETIME_FORMAT } from '../utils/date'
import { format } from '../utils/number'
import Table from '../components/common/Table'
import Input2 from '../components/common/Input2'
import { required } from '../utils/validators'
import Button from '../components/common/Button'
import SearchFilter from '../components/common/SearchFIlter'
import { useQueryParams } from '../hooks/queryString'
import { useModal } from '../hooks/modal'
import ConfirmModal from '../components/common/ConfirmModal'

export default function OrderReturn() {
    const params = useQueryParams()
    const { orderID } = useParams()
    const enabled = orderID !== 'null'
    // const [returnProduct, setReturnProduct] = useState([])
    const { response } = useLoad({
        url: ORDER_DETAIL.replace('{id}', orderID),
        enabled,
        params: { type: 'sold' },
    })
    const orders = useLoad({ url: ORDER_ARCHIVE_LIST, params, enabled: !!params?.search })
    const order = response || orders?.response?.results?.[0] || {}
    const prods = order.orderProduct?.filter((item) => item.type === 'sold').map((item) => ({
        returnedAmount: 0,
        ...item,
        returnQuantity: item.count - (item?.returnedAmount ?? 0),
    })) || []

    console.log(prods)
    const totalForProds = prods.reduce((acc, prod) => {
        const quantity = prod.count
        const { price } = prod
        const total = quantity * price

        acc.total += total

        return acc
    }, { total: 0 })
    const possibleReturnProds = prods.filter((item) => item.count > item.returnedAmount)
    const { loading } = usePutRequest({ url: ORDER_DETAIL.replace('{id}', orderID) })
    const history = useHistory()
    const [showConfirm, hideConfirm] = useModal(
        (values) => (
            <ConfirmModal
                loading={orders.loading}
                onCancel={() => hideConfirm()}
                onConfirm={() => {
                    handleSubmit(values)
                    hideConfirm()
                    history.push('/archive/orders')
                }}
                text="Осуществить возврат ?"
            />
        ),
    )
    const returnProduct = usePutRequest({ url: '/main/order_return_product/{id}'.replace('{id}', orderID) })
    const handleSubmit = (values) => {
        const { success } = returnProduct.request({ data: { items: values?.returnList } })
        if (success) {
            hideConfirm()
        }
    }

    return (
        <Layout>
            <header className="mb-4" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <NavLink className="button is-white is-link is-outlined" to="/archive/orders">
                    <i className="icon ion-md-arrow-back is-size-4" />
                </NavLink>
                <SearchFilter type="number" placeholder="Введите номер чека" />
            </header>

            <Formik
                enableReinitialize
                initialValues={{
                    ...order,
                    returnList: [],
                }}
            >
                {({ values, setFieldValue }) => {
                    const totalForReturnList = values.returnList.reduce((acc, prod) => {
                        const quantity = +prod.returnQuantity
                        const { price } = prod
                        const total = quantity * price

                        acc.total += total

                        return acc
                    }, { total: 0 })
                    return (
                        <Form>
                            <FieldArray
                                name="returnList"
                                render={(arrayHelpers) => (
                                    <main style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', minHeight: '85vh' }}>
                                        <aside style={{ padding: 10, borderRadius: 6, border: '1px solid darkgray', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <div>
                                                <b>
                                                    Кассовый чек №: {order.codeContent}
                                                </b>
                                                <p className="mb-4">
                                                    <b>Дата: </b> {moment(order.createdAt).format(DATETIME_FORMAT)}
                                                </p>

                                                <Table
                                                    columns={{
                                                        n: '№',
                                                        n1: 'Название',
                                                        n2: 'Цена',
                                                        n3: 'Кол-во',
                                                        n4: 'Возвращено',
                                                        n5: 'Сумма оплаты',
                                                    }}
                                                    items={prods.filter((prod) => !values.returnList.map((p) => p?.id).includes(prod?.id))}
                                                    renderItem={(item = {}, i) => (
                                                        <tr
                                                            onClick={() => {
                                                                if (item.count > item.returnedAmount) {
                                                                    arrayHelpers.push(item)
                                                                }
                                                            }}
                                                            key={item.id}
                                                            style={{ cursor: 'pointer' }}
                                                            className={cn(
                                                                css(item.count === item.returnedAmount && styles.disabledRow),
                                                                { 'has-background-warning-light': item.returnedAmount > 0 && item.count > item.returnedAmount },
                                                                { 'has-background-white': item.returnedAmount === 0 },
                                                            )}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{item.product?.name}</td>
                                                            <td>{format(item.price)}</td>
                                                            <td>{item.count}</td>
                                                            <td>{item.returnedAmount}</td>
                                                            <td>{format(item.price * item.count)}</td>
                                                        </tr>
                                                    )}
                                                />

                                                <p className="mt-4" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                                    <span style={{ fontWeight: 'bold' }}>Итого:</span>
                                                    <b>
                                                        {format(totalForProds.total)} сўм
                                                    </b>
                                                </p>
                                            </div>

                                            <Button
                                                text="Возврат всех товаров"
                                                className="mt-4 is-block is-fullwidth is-primary"
                                                onClick={() => {
                                                    setFieldValue('returnList', possibleReturnProds)
                                                }}
                                                disabled={loading || possibleReturnProds.length === 0 || prods.length === values.returnList.length}
                                            />
                                        </aside>

                                        <div style={{ padding: 10, borderRadius: 6, border: '1px solid darkgray', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <article>
                                                <p className="mb-4">
                                                    <b>Возвратный чек</b>
                                                </p>

                                                <Table
                                                    columns={{
                                                        n: '№',
                                                        n1: 'Название',
                                                        n2: 'Цена',
                                                        n3: 'Кол-во возврата',
                                                        n31: 'Возможный возврат',
                                                        n5: 'Сумма оплаты',
                                                        n6: '',
                                                    }}
                                                    items={values.returnList}
                                                    renderItem={(item, i) => (
                                                        <tr
                                                            key={item.id}
                                                            style={{ cursor: 'pointer' }}
                                                            className={css(item.count === item.returnedAmount && styles.disabledRow)}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{item.product?.name}</td>
                                                            <td>{format(item.price)}</td>
                                                            <td>
                                                                <Input2
                                                                    name={`returnList[${i}.returnQuantity]`}
                                                                    type="number"
                                                                    validate={required}
                                                                    disabled={loading}
                                                                    onChange={(e) => {
                                                                        const val = e.target.value
                                                                        const possibleReturn = item.count - item.returnedAmount
                                                                        if (val <= possibleReturn) {
                                                                            setFieldValue(`returnList[${i}.returnQuantity]`, val)
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{item.count - item.returnedAmount}</td>
                                                            <td>{format(item.price * item.count)}</td>
                                                            <td>
                                                                <Button
                                                                    onClick={() => arrayHelpers.remove(i)}
                                                                    icon="close-outline"
                                                                    className="is-danger is-outlined is-size-7"
                                                                    disabled={loading}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                />
                                                <p className="mt-4" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                                    <span style={{ fontWeight: 'bold' }}>Итого:</span>
                                                    <b>
                                                        {format(totalForReturnList.total)} сўм
                                                    </b>
                                                </p>
                                            </article>

                                            <Button
                                                onClick={() => {
                                                    showConfirm(values)
                                                }}
                                                text="Возврат"
                                                className="mt-4 is-block is-fullwidth is-danger"
                                                disabled={loading || values.returnList.length === 0}
                                            />
                                        </div>
                                    </main>
                                )}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </Layout>
    )
}

const styles = StyleSheet.create({
    disabledRow: {
        cursor: 'not-allowed',
        textDecoration: 'line-through',
    },
})
