import React from 'react'
import Button from './Button'

export default function ConfirmModal({ onConfirm, onCancel, loading, children, text = '' }) {
    return (
        <>
            {text && (
                <p className="is-size-5 has-text-weight-semibold">{text}</p>
            )}
            {children}
            <div
                style={{ marginTop: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}
            >
                <Button
                    loading={loading}
                    onClick={() => { onConfirm() }}
                    text="Подтвердить"
                    className="is-primary"
                />
                <Button
                    onClick={onCancel}
                    text="Отмена"
                    className="is-outlined"
                    disabled={loading}
                />
            </div>
        </>
    )
}
