import React from 'react'
import { BORROW_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import BorrowForm from './BorrowForm'

export default function BorrowUpdate({ hideModal, item, borrows }) {
    const borrowUpdate = usePutRequest({ url: BORROW_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await borrowUpdate.request({ data })
        if (success) {
            borrows.request()
            hideModal()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Изменить товар</b>
            </h1>
            <BorrowForm loading={borrowUpdate.loading} onCancel={hideModal} initialValues={{ ...item }} onSubmit={onSubmit} />
        </div>
    )
}
