import React from 'react'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { useQueryParams } from '../hooks/queryString'
import BonusTableItem from '../components/BonusTableItem'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import ClientCreate from '../components/ClientCreate'
import NewTable from '../components/common/NewTable'
import SearchFilter from '../components/common/SearchFIlter'

export default function Bonus() {
    const params = useQueryParams()
    const clients = useLoad({ url: '/main/bonus', params: { ...params } })
    const clientList = clients.response ? clients.response.results : []

    const [showCreateModal, setShowCreateModal] = useModal(<ClientCreate clients={clients} onCancel={() => setShowCreateModal()} />)

    return (
        <Layout>
            <div className="is-flex">
                <SearchFilter />
                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2 is-justify-content-right" onClick={showCreateModal} />
            </div>
            <NewTable
                loading={clients.loading}
                items={clientList}
                columns={{ index: '', name: 'Имя', address: 'Номер телефона', price: 'Общая сумма чеки', createdAt: 'Дата', buttons: '' }}
                totalCount={clients.response ? clients.response.count : 30}
                pageSize={15}
                renderItem={(item, index) => <BonusTableItem clients={clients} index={index} key={item.id} item={item} />}
            />
        </Layout>
    )
}
