import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useLoad } from '../hooks/request'
import { BORROW_LIST, CLIENT_DETAIL } from '../urls'
import empty from '../static/empty-product.png'
import Table from './common/Table'
import ClientBorrowItem from './ClientBorrowItem'
import { useQueryParams } from '../hooks/queryString'
import BorrowCreate from './BorrowCreate'
import Button from './common/Button'
import { useCustomModal } from '../hooks/customModal'
import Layout from './Layout'
import { format } from '../utils/number'

export default function ClientBorrowList() {
    const { clientId } = useParams()
    const { params } = useQueryParams()
    const [page, setPage] = useState(1)
    const { response: client } = useLoad({ url: CLIENT_DETAIL.replace('{id}', clientId) })
    const borrows = useLoad({ url: BORROW_LIST, params: { page, ...params, client_id: clientId, status: 'active' } }, [params, page])
    const borrowItem = borrows.response ? borrows.response.results : []
    const total = borrowItem.reduce((prev, current) => prev + current.amount, 0)

    const [showCreateModal, hideCreateModal] = useCustomModal(<BorrowCreate borrows={borrows} clientId={clientId} onCancel={() => hideCreateModal()} />, styles.modal)
    const [showPayModal, hidePayModal] = useCustomModal(<BorrowCreate borrow borrows={borrows} clientId={clientId} onCancel={() => hidePayModal()} />, styles.modal)

    return (
        <Layout>
            <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <hgroup style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Link className="button is-white is-link is-outlined" to="/borrow">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </Link>
                    <span className="is-size-4">
                        <b>
                            {client?.name} - {client?.phoneNumber}
                        </b>
                    </span>
                </hgroup>
                <article className={total < 0 ? 'tag is-danger is-light is-medium' : 'tag is-link is-light is-medium'}>
                    <h3 className="is-size-6 mr-4">Все Долги:</h3>
                    <p className="is-size-6" style={{ fontWeight: '600' }}>
                        {format(total)} сум
                    </p>
                </article>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button onClick={showPayModal} icon="cash-outline" text="Платить " className="is-info is-outlined" />
                    <Button onClick={showCreateModal} icon="add-outline" text="Добавить новый долг" className="is-link is-outlined" />
                </div>
            </header>

            <hr />

            {borrows.response && borrows.response.count === 0 ? (
                <div>
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <h1>
                            <b>Не долг</b>
                        </h1>
                    </div>
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                </div>
            ) : (
                <div>
                    <Table
                        loading={borrows.loading}
                        items={borrowItem}
                        columns={{ pridasce: '', price: 'Цена', name: 'Описание', registered_at: 'Создан в', sactions: '', actions: '' }}
                        totalCount={borrows.response ? borrows.response.count : 0}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <ClientBorrowItem index={index} borrows={borrows} clientId={clientId} key={item.id} item={item} />}
                    />
                </div>
            )}
        </Layout>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' }, emptyImg: { width: '330px', height: '300px' }, loader: { marginTop: '2rem' }, modal: { width: 800 } })
