import React from 'react'
import { PRODUCT_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import DocumentsProductForm from './DocumentsProductForm'

export default function DocumentsProductUpdate({ onCancel, item, refresh, arrayHelp }) {
    const [showMessage] = useMessage()
    const update = usePutRequest({ url: PRODUCT_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success, error, response } = await update.request({ data: {
            ...data,
            usd_price: data.usd_price || undefined,
            usd_price_sale: data.usd_price_sale || undefined,
            usd_price_transfer: data.usd_price_transfer || undefined,
        } })
        if (success) {
            refresh()
            showMessage('Успешно обновлен', 'is-success')
            onCancel()
            if (arrayHelp) {
                arrayHelp.arrayHelpers.replace(arrayHelp.index, {
                    ...response,
                    quantity: '',
                    count: response?.count || '',
                    price: response?.price || '',
                    priceSale: response?.priceSale || '',
                    priceTransfer: response?.priceTransfer || '',
                })
            }
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить товар</b></h1>

            <DocumentsProductForm
                onCancel={onCancel}
                onSubmit={onSubmit}
                loading={update.loading}
                initialValues={item}
            />
        </div>
    )
}
