import { isEmpty } from 'lodash'
import queryString from 'querystring'
import { StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'

import Layout from '../../components/Layout'
import Search from '../../components/Search'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import { useModal } from '../../hooks/modal'
import { useQueryParams } from '../../hooks/queryString'
import { useLoad } from '../../hooks/request'
import { EXPENSE_REPORT_LIST } from '../../urls'
import ExpensesColumn from './Column'
import ExpenseCreate from './expense-create'

export default function Expenses() {
    const expenseReport = useLoad({ url: EXPENSE_REPORT_LIST })
    const params = useQueryParams()
    const [page, setPage] = useState(1)

    const productList = expenseReport.response ? expenseReport.response.results : []
    const [showCreateModal, setShowCreateModal] = useModal(<ExpenseCreate expenseReport={expenseReport} onCancel={() => setShowCreateModal()} />, styles.modal)
    const history = useHistory()

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout showCreateModal={showCreateModal}>
            <div className="is-flex mb-3">
                <Search onSearch={onSearch} />

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
            </div>

            <div className="mt-4">
                <Table
                    loading={expenseReport.loading}
                    items={productList}
                    columns={{
                        index: '№',
                        name: 'Торговая точка',
                        cash: 'Касса',
                        cashier: 'Кассир',
                        expenseType: 'Тип расхода',
                        note: 'Примечание',
                        expenseAmount: 'Сумма расходов',
                        date: 'Дата',
                        buttons: '',
                    }}
                    totalCount={expenseReport.response ? expenseReport.response.count : 0}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => <ExpensesColumn expenseReport={expenseReport} index={index} key={item.id} item={item} />}
                />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
