import React from 'react'
import { usePostRequest } from '../hooks/request'
import { ORDER_LIST } from '../urls'
import CheckForm from './CheckForm'

export default function CheckCreate({ onCancel, reload }) {
    const products = usePostRequest({ url: ORDER_LIST })

    async function onSubmit(data) {
        const { success } = await products.request({
            data: {
                ...data,
                client: data.client.id,
                items: [],
            },
        })
        if (success) {
            onCancel()
            reload.request()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Добавить новый чек</b>
            </h1>
            <CheckForm active reload={reload} loading={products.loading} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
